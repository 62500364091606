@use 'sass:color';
$primary-color: #706e6e; // Dark grey – headers, footers
$secondary-color: #b8b8b8; // Mid grey – cards, background elements
$accent-color: #999999; // Light grey – highlighted text, buttons
$button-color: #a4a2a2; // Black – buttons, links
$background-color: #efefef; // Ligh grey – background
$highlight-color: #d9d9d9; // Highlighted bbuttons, links
$text-color: #000000; // Default text color

// Generate utility classes for colors and hover states based on the color map
$colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'accent': $accent-color,
  'button': $button-color,
  'background': $background-color,
  'highlight': $highlight-color,
  'text': $text-color,
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }

  .border-#{$name} {
    border-color: $color;
  }

  .bg-#{$name}-hover:hover {
    background-color: color.mix($color, rgba(0, 0, 0, 0.5));
  }

  .text-#{$name}-hover:hover {
    color: color.mix($color, rgba(0, 0, 0, 0.5));
  }
}

// Add styles for every second child of offer_list
.offer_list > div:nth-child(2n),
.dlc_list > div:nth-child(2n) {
  background-color: $background-color;
  border-color: $highlight-color;
}
.offer_list > div:nth-child(2n):hover,
.dlc_list > div:nth-child(2n):hover {
  background-color: color.mix($highlight-color, rgba(0, 0, 0, 0.5));
}
