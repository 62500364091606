$qr-content-size: 200px;
$qr-padding: 5px;

.qr-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $qr-padding;
  align-content: center;
  width: calc(#{$qr-content-size} + 2 * #{$qr-padding});
  height: calc(#{$qr-content-size} + 2 * #{$qr-padding});
}

.qr-content {
  width: 100%;
  height: 100%;
  transform: scale(2.6);
  transform-origin: 0 0;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
  }
}

@mixin qr-styles($content-size, $padding, $scale) {
  .qr-container {
    padding: $padding;
    width: calc(#{$content-size} + 2 * #{$padding});
    height: calc(#{$content-size} + 2 * #{$padding});
  }
  .qr-content {
    width: 100%;
    height: 100%;
    transform: scale($scale);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  @include qr-styles(308px, 5px, 4);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  @include qr-styles(308px, 5px, 4);
}

@media screen and (min-width: 1025px) {
  @include qr-styles(320px, 10px, 4.1);
}