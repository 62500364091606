.best-deal {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: white;
  font-size: 15px;
  padding: 5px 60px;
  transform: rotate(33deg) translate(40%, 110%);
  transform-origin: top right;

  /* Form of a trapezoid*/
  clip-path: polygon(25% 0%, 75% 0%, 87% 100%, 0% 100%);
}
